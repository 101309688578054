export interface CreditCard {
  creditCardGuid: string
  creditCardType: string
  firstSixDigits: string
  lastFourDigits: string
  expirationDate: string
  active?: boolean
  associateId?: number
  associatePaymentInformationId?: number
  currencyCode?: string
}

export interface OneTimeTokenResponse {
  nexioOneTimeTokenIframe: {
    token: string
  }
}

export interface CreditCardsForAssociateResponse {
  nexioCreditCardsForAssociate: CreditCard[]
}

export interface NexioRedirectResult {
  redirectUrl: string
  pendingTransactionUid: string
}
export interface NexioErrorResult {
  error: number
  message: string
}

export interface NexioProcessTransactionResponse {
  success: boolean
  redirect: boolean
  error: boolean
  redirectResult?: NexioRedirectResult
  errorResult?: NexioErrorResult
}

export interface NexioConfirmTransactionResponse {
  isConfirmed: boolean
  message: string
}

export interface NexioToken {
  firstSix: string
  lastFour: string
  cardType: string
  token: string
}

export interface NexioCard {
  cardHolderName: string
  cardType: string
  expirationMonth: string
  expirationYear: string
}

export interface NexioTokenSavedResponse {
  token: NexioToken
  card: NexioCard
  data: any //uneeded info at the moment
}

export interface NexioFormValidation {
  isFormValid: boolean
}

export interface NexioFormError {
  error: number
  message: string
}

export const convertNexioToQSCard = (nexioCard, nexioToken, currencyCode) => {
  const variables = {
    creditCardGuid: nexioToken.token,
    creditCardType: nexioToken.cardType,
    currencyCode,
    firstSixDigits: nexioToken.firstSix,
    lastFourDigits: nexioToken.lastFour,
    expirationDate: `${nexioCard.expirationYear}-${nexioCard.expirationMonth}-01`,
  }
  return { inputSaveCardData: variables }
}

export interface NexioProcessTransData {
  magentoOrderId: string
  creditCardGuid: string
  customerRedirectUrl: string
}

export interface NexioProcessTransDataV2 {
  magentoOrderIncrementId: string
  creditCardGuid: string
  customerRedirectUrl: string
}

export interface NexioConfirmTransData {
  pendingTransactionUid: string
  cancel: boolean
}
